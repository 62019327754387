import React, { useState, useEffect } from "react";
import axios from 'axios';

import { useParams, useNavigate } from "react-router-dom";
import { Select, Row, Col, message } from 'antd';

import SingleTripTable from "./SingleTripTable";
import SingleTripMap from "./SingleTripMap";
import TripTable from "./TripTable";


export default function SingleTrip(props){

    const [data, setData] = useState([]);
    const [eventsCount, setEventsCount] = useState(0);
    const [mapData, setMapData] = useState([]);
    const [tripId, setTripId] = useState(null);
    const [trip, setTrip] = useState([])
    const [tripList, setTripList] = useState([])
    const [loadingRecords, setLoadingRecords] = useState(false);
    const [loadingTrip, setLoadingTrip] = useState(false);
    const [showAllEvents, setShowAllEvents] = useState(false);
    const [adjacentTrips, setAdjacentTrips] = useState({});
    const params = useParams();
    const navigate = useNavigate();

    const fetchTripEvents = async (tripId, page = 1, pageSize = 500) => {
        setLoadingRecords(true);
        let qparams = {};
        qparams["page"] = page;
        qparams["pageSize"] = pageSize
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/'+ tripId + '/events', {params: qparams})
            .then(response => {
                if (response.status === 200){
                    if (response.data.events.length > 0) {
                        setData(response.data)
                        setEventsCount(response.data.page_size)
                        setMapData(response.data.events.slice(0,10))
                    }
                    else {
                        message.warning("Trip with id " + tripId + " doesn't exist...")
                        if (props.trips.length > 0) navigate("/singletrip/" + props.trips[0].trip_id, { replace: true })
                    }
                } else {
                    console.log(response);
                    message.error("Could not load events for trip " + tripId + "...")
                }
                setLoadingRecords(false);
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load events for trip " + tripId + "...")
                setLoadingRecords(false);
            });
    }

    async function getTripEventsAtPage(page = 1, pageSize = 500) {
        let qparams = {
            page: page,
            pageSize: pageSize
        };
    
        try {
            const response = await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId + '/events', { params: qparams });
            
            if (response.status === 200) {
                if (response.data.events.length > 0) {
                    return response.data;
                } else {
                    message.warning("Trip with id " + tripId + " doesn't exist...");
                    return [];  // or handle accordingly
                }
            } else {
                console.log(response);
                message.error("Could not load events for trip " + tripId + "...");
                return null;  // or handle accordingly
            }
        } catch (error) {
            console.log(error);
            message.error("Could not load events for trip " + tripId + "...");
            return null;  // or handle accordingly
        }
    }
    

    useEffect(() => {
        if (params?.tripId != null) {
            setTripId(params.tripId)
        } else if (props.trips.length > 0) {
            navigate("/singletrip/" + props.trips[0].trip_id, { replace: true })
        }
    },[params])

    useEffect(() => {
        // Generate trip id list
        setTripList(props.trips.map(row => {return {"value": row.trip_id}}))
        // If tripId is null => set first id
        // if (tripId == null && props.trips.length > 0) setTripId(props.trips[0].trip_id)
        // if (tripId != null){
        //     // Check if current tripId is part of new trips
        //     const newTripList = props.trips.map(row => row.trip_id)
        //     if (!newTripList.includes(tripId)) setTripId(props.trips[0].trip_id)
        // }
    },[props.trips])

    useEffect(async () => {
        if (tripId == null) return
        // fetch events for trip
        await fetchTripEvents(tripId)
        // Use trip id to fetch trip info, or generate from event info if not in the lookup
        const tripList = props.trips.map(row => row.trip_id)
        if (tripList.includes(tripId)) {
            setLoadingTrip(true)
            let current_trip = props.trips.filter(row => row.trip_id === tripId)

            // get adjacent trip ids
            await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId + '/adjacent')
                .then(response => {
                    if (response.status === 200){
                        current_trip[0].next_trip = response.data.next;
                        current_trip[0].prev_trip = response.data.prev;
                        setTrip(current_trip);
                        
                    } else {
                        setTrip(current_trip);
                        console.log(response);
                        message.error("Could not load adjacent trip ids for trip "+tripId+"...")
                    }
                    setLoadingTrip(false);
                })
                .catch(error => {
                    console.log(error);
                    message.error("Could not load adjacent trip ids for trip "+tripId+"...")
                    setLoadingTrip(false);
                });

        } else {
            setLoadingTrip(true)
            await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId)
                .then(response => {
                    if (response.status === 200){
                        setTrip([response.data]);
                        
                    } else {
                        console.log(response);
                        message.error("Could not load details for trip "+tripId+"...")
                    }
                    setLoadingTrip(false);
                })
                .catch(error => {
                    console.log(error);
                    message.error("Could not load details for trip "+tripId+"...")
                    setLoadingTrip(false);
                });

        }
    }, [tripId])
    

    const tripSelectionBox =
        <Select
            style={{width: '100%'}}
            value={tripId}
            options={tripList}
            onChange={val => navigate("/singletrip/" + val)}
            placeholder="Select trip..."
        />

    function setDataOnPage(newData){

        setMapData(newData)
    }

    const updateTripRow = (trip_id, updated) => {

        setTrip(prevState => 
            prevState.map(trip => 
                trip.trip_id === trip_id
                ? { ...trip,
                    ...updated }
                : trip
            )
        );
    }

    return (
        <div style={{padding: 4, height: '100%'}}>
            <Row gutter={4} type="flex" style={{ marginBottom: 12}}>
                <Col span={24}>
                    <TripTable data={trip} loading={loadingRecords || loadingTrip} tripSelectionBox={tripSelectionBox} updateTripRow={updateTripRow} />
                </Col>
            </Row>
            <Row gutter={4} style={{height: props.intFrameHeight - props.appBarHeight - 168, minHeight: 300, marginBottom: 8}}>
                <Col xl={8} md={24} style={{paddingBottom: 4}}>
                    <SingleTripMap tripEvents={showAllEvents ? data.events : mapData} setShowAllEvents={setShowAllEvents} parkingSlots={props.parkingSlots} trip={trip} />
                </Col>
                <Col xl={16} md={24}>
                    <SingleTripTable
                        data={data}
                        eventsCount={eventsCount}
                        loading={loadingRecords}
                        tripId={tripId}
                        scroll={props.intFrameHeight - props.appBarHeight - 168}
                        fetchTripEvents={fetchTripEvents}
                        setDataOnPage={setDataOnPage}
                        getTripEventsAtPage={getTripEventsAtPage}
                    />
                </Col>
            </Row>
       </div>
    )

}
