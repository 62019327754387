import React, { useEffect, useState, PureComponent } from 'react';
import { DatePicker, Select, Button, message, Typography } from 'antd';
import ProTable from '@ant-design/pro-table';
import axios from 'axios';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const { Text, Title } = Typography;
const { Option } = Select;

export default function Truck(props) {
    const [data, setData] = useState([]);

    const [truckTags, setTruckTags] = useState([]);  // For truck selection
    const [truckDate, setTruckDate] = useState(null);  // For date selection
    const [loading, setLoading] = useState(false);

    // Chart series and axes configuration
    const series = { type: 'bar' };
    const axes = [
        {
            primary: true,
            type: 'time',
            position: 'bottom',
            show: true,
            tickCount: data[0]?.data.length || 24,  // Adjust for the number of hours
            format: (hour) => `${hour * 1}:00`
        },
        { type: 'linear', position: 'left' }
    ];

    // Fetch data based on selected truck and date
    const fetchData = async () => {
        if (!truckTags || !truckDate) {
            message.error("Please select both truck and date.");
            return;
        }

        setLoading(true);

        try {
            const response = await axios.get(`${window.vtrack_config.REACT_APP_API_URL}/metrics/trips_per_hour`, {
                params: {
                    truck_tags: truckTags,
                    chosen_date: truckDate.format('YYYY-MM-DD')
                }
            });

            if (response.status === 200) {
                const resultData = response.data;
                let chartData = [];

                for (const [truckTag, metrics] of Object.entries(resultData)) {
                    const hours = Array.from({ length: 24 }, (_, i) => ({ hour: i, trip_count: 0 })); // Create an array of hours from 0 to 23
                    const dataMap = new Map(metrics.map(item => [item.hour, item])); // Map original data by hour

                    for (const hour of hours) {
                        if (dataMap.has(hour.hour)) {
                            hour.trip_count = dataMap.get(hour.hour).trip_count;
                        }
                    }
                    // Calculate total trips for this truck
                    const total = metrics.reduce((sum, item) => sum + item.trip_count, 0);
                    // Add the data for this truck to chartData
                    chartData.push({
                        label: truckTag,
                        data: hours.map(d => ({ hour: d.hour, trip_count: d.trip_count })),
                        total_count: total
                    });
                }
                console.log(chartData)
                setData(chartData);
            } else {
                message.error("Failed to load trips data.");
            }
        } catch (error) {
            console.log(error);
            message.error("An error occurred while fetching the data.");
        }

        setLoading(false);
    };

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>

            <Title level={2}>Trips per Day</Title>

            {/* Truck selection */}
            <div>
                <Text>Select Truck:</Text>
                <Select
                    style={{ width: 200, marginLeft: 10 }}
                    placeholder="Select truck"
                    mode="multiple"
                    allowClear
                    value={truckTags}
                    onChange={(value) => setTruckTags(value)}
                    options={props.dataZoomTrucksLookup}
                >
                </Select>
            </div>

            {/* Date selection */}
            <div style={{ marginTop: 20 }}>
                <Text>Select Date:</Text>
                <DatePicker
                    style={{ marginLeft: 10 }}
                    onChange={(date) => setTruckDate(date)}
                    placeholder="Select date"
                />
            </div>

            {/* Fetch data button */}
            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={fetchData} loading={loading}>
                    Fetch Data
                </Button>
            </div>

            {/* Charts */}
            <div style={{ marginTop: 20 }}>
                {data.map((truckData, index) => (
                    <div key={index} style={{ marginBottom: 40 }}>
                        <Title level={4}>{truckData.label}</Title>
                        <Text>Total Trips: {truckData.total_count}</Text>  {/* Display total trips */}
                        <div style={{ height: '400px' }}>
                            {/* <Chart data={[truckData]} series={series} axes={axes} tooltip /> */}
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={200}
                                    data={truckData.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="hour" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="trip_count" fill="#8884d8" activeBar={<Rectangle fill="rgb(84, 20, 107)" stroke="blue" />} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
