import React, { useEffect, useState } from "react";

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Modal, Input, Table, ConfigProvider, Spin, Checkbox } from 'antd';
import {
    Typography,
    Button,
    Row,
    Col
} from 'antd';
const { Text } = Typography;

export default function TripTrailerMoal({trailerImageLookup, selectedTrailerImage, tripTrailerImages, trailerTripId, trailerImagesModal,
    handleModalCancel, handleTripTrailerOk, trailerSelectedLoading, setManualOverwrite, manualOverwrite, userApprovedChecked, handleTrailerImageSelect, tripTrailerColumns, trailerImagesLoading,
    setSelectedTrailerImage, invalidTrip, setInvalidTrip
}) {


    function handleTrailerImageChange(direction) {
        if (trailerImageLookup.length == 0) {
            return;
        }
        let currentIndex = selectedTrailerImage.index;

        //true is next, false is previous
        if (direction) {
            if (currentIndex + 1 >= trailerImageLookup.length) {
                currentIndex = 0;
            } else {
                currentIndex += 1;
            }
        } else {
            if (currentIndex - 1 < 0) {
                currentIndex = trailerImageLookup.length - 1;
            } else {
                currentIndex -= 1;
            }
        }

        setSelectedTrailerImage({ data: tripTrailerImages[trailerImageLookup[currentIndex]["index"]]["image_data"], index: currentIndex, name: tripTrailerImages[trailerImageLookup[currentIndex]["index"]]["displayname"], id: tripTrailerImages[trailerImageLookup[currentIndex]["index"]]["trailer_number"] })
    }

    return (
        <Modal
            title={"OCR Detected Trailer IDs - " + trailerTripId}
            open={trailerImagesModal}
            onCancel={handleModalCancel}
            onOk={handleTripTrailerOk}
            width={"90%"}
        >
            <Row style={{ marginTop: 16, marginBottom: 16 }}>
                <Col span={18}>
                    <Row gutter={16} justify={"center"} align={"middle"}>
                        <Col span={2}>
                            <Button size="large"
                                icon={<LeftOutlined />}
                                onClick={() => handleTrailerImageChange(false)}>
                            </Button>
                        </Col>

                        <Col span={14} style={{ textAlign: "center" }}>
                            <Spin spinning={trailerSelectedLoading}>
                                {
                                    selectedTrailerImage ? (
                                        <>
                                            <img
                                                src={`data:image/jpeg;base64,${selectedTrailerImage.data}`}
                                                alt="Trailer Image"
                                                style={{ width: '100%', height: '200px', objectFit: 'contain' }}
                                            />
                                            <Text>{selectedTrailerImage.name}</Text>
                                        </>
                                    ) : (
                                        <Text>No Image Available</Text>
                                    )}
                            </Spin>
                        </Col>

                        <Col span={2}>
                            <Button size="large"
                                icon={<RightOutlined />}
                                onClick={() => handleTrailerImageChange(true)}>

                            </Button>
                        </Col>
                    </Row>

                </Col>
                <Col span={6}>
                    <Col flex="auto"
                        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Text strong style={{ marginRight: 8 }}>Overwrite:</Text>
                        <Input
                            value={manualOverwrite.toUpperCase()}
                            onChange={(e) => setManualOverwrite(String(e.target.value).toUpperCase())}
                            disabled={invalidTrip ? true : manualOverwrite ? false : userApprovedChecked}
                            style={{ flex: '1 1 auto' }}
                        />
                    </Col>
                    <Col flex="auto" style={{ marginTop: 32 }}>
                        <Checkbox
                            checked={invalidTrip}
                            onChange={(e) => setInvalidTrip(e.target.checked)}
                        >
                            Wrong object
                        </Checkbox>
                    </Col>
                </Col>
            </Row>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            rowSelectedBg: '#ba80f0'
                        }
                    }
                }}
            >

                <Table
                    dataSource={tripTrailerImages}
                    rowSelection={{ type: "radio", onChange: handleTrailerImageSelect, selectedRowKeys: selectedTrailerImage != null ? [selectedTrailerImage["id"]] : [] }}
                    columns={tripTrailerColumns}
                    rowKey="trailer_number"
                    bordered
                    pagination={false}
                    loading={trailerImagesLoading}
                />
            </ConfigProvider>
        </Modal>
    )
}
