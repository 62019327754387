import React, { useState } from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Typography, Tag, Table, Button, Input } from 'antd';
const { Text } = Typography;
import { getTripDuration, singleTripURL } from "../Helpers";
import axios from 'axios';


export default function TripTable(props) {

    const [editableFields, setEditableFields] = useState({});
    const [editingRowKey, setEditingRowKey] = useState({ trip_id: 0 });

    const handleEditClick = (trip_id, value, field) => {
        setEditingRowKey({ trip_id: trip_id, field: field });
        setEditableFields({
            [field]: value
        });
    };

    const handleCancel = () => {
        setEditingRowKey({ trip_id: 0 }); // cancel edit
    };

    const handleSave = (trip_id) => {


        axios.post(window.vtrack_config.REACT_APP_API_URL + `/trips/${trip_id}/edit`, editableFields)
            .then(response => {
                console.log(response.data);
                //props.fetchData(); // refresh data after save
                setEditingRowKey({ trip_id: 0 });
                props.updateTripRow(trip_id, response.data);
            })
            .catch(error => {
                console.error("There was an error updating the trip!", error);
            });

    };

    const columns = [
        {
            title: 'Selected Trip', width: 180, render: (_val, record) =>
                <>
                    {props.tripSelectionBox}<br />
                    <div>
                        <a href={singleTripURL(record.prev_trip)}>
                            <Button type="primary" disabled={!record.prev_trip}>
                                Previous Trip
                            </Button>
                        </a>
                        <a href={singleTripURL(record.next_trip)}>
                            <Button type="primary" disabled={!record.next_trip} style={{ marginLeft: 8 }}>
                                Next Trip
                            </Button>
                        </a>
                    </div>
                </>
        },
        {
            title: 'Trip Info', width: 180, sortDirections: ['ascend', 'descend'], render: (_val, record) =>
                <>
                    <Text strong>Trip ID: </Text><Text>{record.trip_id}</Text><br />
                    <Text strong>Tractor ID: </Text><Text>{record.tag}</Text><br />
                    <Text strong>Trip duration: </Text> {getTripDuration(record)}
                </>
        },
        {
            title: 'Created/Updated', width: 220, render: (_val, record) =>
                <>
                    <Text strong>Created: </Text><Text>{!!record.created ? moment.utc(record.created).format('DD/MM/YYYY HH:mm:ss') : record.created}</Text><br />
                    <Text strong>Updated: </Text><Text>{!!record.updated ? moment.utc(record.updated).format('DD/MM/YYYY HH:mm:ss') : record.updated}</Text>
                </>
        },
        {
            title: 'Status', width: 70, render: (_val, record) => {
                let trip_type_name = record.trip_type_rel ? record.trip_type_rel.name : null
                switch (record.trip_type) {
                    case 1:
                        return <Tag color="#509423">{trip_type_name ? trip_type_name : "UNLOAD"}</Tag>;
                    case 2:
                        return <Tag color="#e6833c">{trip_type_name ? trip_type_name : "LOAD"}</Tag>;
                    case 3:
                        return <Tag color="#666">{trip_type_name ? trip_type_name : "YARD SHIFT"}</Tag>;
                    case 4:
                        return <Tag color="#aaa">{trip_type_name ? trip_type_name : "VESSEL PICKUP"}</Tag>;
                    case 5:
                        return <Tag color="#aaa">{trip_type_name ? trip_type_name : "YARD PICKUP"}</Tag>;
                }
            }
        },
        {
            title: 'Pickup', width: 210, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Time: </Text><Text>{!!record.pickup_time ? moment.utc(record.pickup_time).format('DD/MM/YYYY HH:mm:ss') : record.pickup_time}</Text>
                    <Text strong>Latitude: </Text><Text>{record.pickup_latitude}</Text><br />
                    <Text strong>Longitude: </Text><Text>{record.pickup_longitude}</Text><br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                                <Input value={editableFields.pickup_yard_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.pickup_yard_id, 'pickup_yard_id')}>Edit</Text>
                            </div>
                        )}

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck ID: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                                <Input value={editableFields.pickup_deck_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} className="edit-button"
                                    onClick={() => handleEditClick(record.trip_id, record.pickup_deck_id, "pickup_deck_id")}
                                >Edit</Text>
                            </div>
                        )}

                    </div>
                </React.Fragment>
        },
        {
            title: 'Discharge', width: 210, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Time: </Text><Text>{!!record.discharge_time ? moment.utc(record.discharge_time).format('DD/MM/YYYY HH:mm:ss') : record.discharge_time}</Text>
                    <Text strong>Latitude: </Text><Text>{record.discharge_latitude}</Text><br />
                    <Text strong>Longitude: </Text><Text>{record.discharge_longitude}</Text><br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                                <Input value={editableFields.discharge_yard_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.discharge_yard_id, 'discharge_yard_id')}>Edit</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck ID: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                                <Input value={editableFields.discharge_deck_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} onClick={() => handleEditClick(record.trip_id, record.discharge_deck_id, "discharge_deck_id")}>Edit</Text>
                            </div>
                        )}
                    </div>
                </React.Fragment>
        },
        {
            title: 'Trailer', width: 200, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{record.trailer_number}</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? moment.utc(record.trailer_number_time).format('DD/MM/YYYY HH:mm:ss') : record.trailer_number_time}</Text>
                </React.Fragment>
        },
    ];

    return (
        <>
            <style jsx>{`
                .edit-button {
                    visibility: hidden;
                }
                .edit-button > span {
                    color: blue;
                }
                .editable-row:hover .edit-button {
                    visibility: visible;
                }
            `}</style>
            <Table
                columns={columns}
                size="small"
                loading={props.loading}
                pagination={false}
                bordered={true}
                rowKey="trip_id"
                dataSource={props.data}
                style={{ overflowX: 'scroll' }}

            />
        </>
    )
}
