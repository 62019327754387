import React, { useEffect, useState } from "react";
import moment from 'moment';
import { mergedTripLink } from "../Helpers";
import { MergeCellsOutlined, CameraOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Modal, Checkbox, DatePicker, Input, message, Divider } from 'antd';
const { RangePicker } = DatePicker;
import axios from 'axios';

import {
    Typography, Tag,
    Radio,
    Button,
    InputNumber,
    Row,
    Col,
    Slider
} from 'antd';
const { Text } = Typography;

import ProTable from '@ant-design/pro-table';

import TripEventSubTable from "./TripEventSubTable";
import { getTripDuration } from "../Helpers";
import TripTrailerMoal from "./TripTrailerModal";


export default function TripTable(props) {

    const [leftDuration, setLeftDuration] = useState(null);
    const [rightDuration, setRightDuration] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [tripIdSearch, setTripIdSearch] = useState(null);

    const [merging, setMerging] = useState(false);
    const [mergingOpen, setMergingOpen] = useState(false);
    const [mergeThreshold, setMergeThreshold] = useState(0);
    const [mergeMinutes, setMergeMinutes] = useState(1);
    const [mergeSeconds, setMergeSeconds] = useState(null);
    const [dateWithTime, setDateWithTime] = useState(false);
    const [mergeDateRange, setMergeDateRange] = useState({})

    const [trailerImagesModal, setTrailerImagesModal] = useState(false);
    const [tripTrailerImages, setTripTrailerImages] = useState([]);
    const [initialTripTrailer, setInitialTripTrailer] = useState([]);
    const [selectedTrailerImage, setSelectedTrailerImage] = useState(null);
    const [trailerImageLookup, setTrailerImageLookup] = useState([]);
    const [trailerImagesLoading, setTrailerImagesLoading] = useState(false);
    const [trailerSelectedLoading, setTrailerSelectedLoading] = useState(false);
    const [trailerTripId, setTrailerTripId] = useState(null);
    const [manualOverwrite, setManualOverwrite] = useState("");
    const [userApprovedChecked, setUserApprovedChecked] = useState(false);
    const [invalidTrip, setInvalidTrip] = useState(false);

    const [verificationTypes, setVerificationTypes] = useState({});

    const [editableFields, setEditableFields] = useState({});
    const [editingRowKey, setEditingRowKey] = useState({ trip_id: 0 });

    useEffect(() => {
        setMergeThreshold(mergeMinutes + (mergeSeconds / 60));
    }, [mergeMinutes, mergeSeconds])

    useEffect(() => {
        if (invalidTrip) {
            setManualOverwrite("");
            setUserApprovedChecked(false);
        }
    }, [invalidTrip])

    useEffect(() => {
        const result =props.verificationTypes.reduce((acc, item) => {
            acc[item.id] = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            return acc;
        }, {});
        setVerificationTypes(result);
    }, [props.verificationTypes])

    //Start editable trip fields

    const handleEditClick = (trip_id, value, field) => {
        setEditingRowKey({ trip_id: trip_id, field: field });
        setEditableFields({
            [field]: value
        });
    };

    const handleCancel = () => {
        setEditingRowKey({ trip_id: 0 }); // cancel edit
    };

    const handleSave = (trip_id) => {


        axios.post(window.vtrack_config.REACT_APP_API_URL + `/trips/${trip_id}/edit`, editableFields)
            .then(response => {
                //props.fetchData(); // refresh data after save
                setEditingRowKey({ trip_id: 0 });
                props.updateTripRow(trip_id, response.data);
            })
            .catch(error => {
                console.error("There was an error updating the trip!", error);
            });

    };

    //END editable trip fields

    function resetTripTrailerState() {
        setTrailerImagesModal(false);
        setTripTrailerImages([]);
        setSelectedTrailerImage(null);
        setUserApprovedChecked(false);
        setInvalidTrip(false);
    }

    async function fetchTripTrailerImages(tripId) {
        setTrailerSelectedLoading(true);
        setTrailerImagesLoading(true);
        setTrailerTripId(tripId);
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId + '/trailer_id_image')
            .then(response => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setTripTrailerImages(response.data);
                        setInitialTripTrailer(response.data);

                        let tempTrailerImageLookup = []
                        let selectedRow = -1;
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i]["image_data"] != "" && response.data[i]["image_data"] != null) {

                                tempTrailerImageLookup.push({ index: i, count: response.data[i]["updated_count"], confidence: response.data[i]["confidence"] });
                            }
                            if (response.data[i]["manual_overwrite"] || response.data[i]["user_approved"]) {
                                setUserApprovedChecked(true);
                                if (response.data[i]["user_approved"]) {
                                    selectedRow = i;
                                }
                            }
                        }
                        tempTrailerImageLookup.sort((a, b) => b.count - a.count);
                        let selectedIndex = 0;
                        let lookUpIndex = 0;
                        //if selected index was not set with the approved trailer image, set one with highest updated_count
                        if (selectedRow >= 0) {
                            lookUpIndex = tempTrailerImageLookup.findIndex((img) => { return img.index === selectedRow });

                        }
                        selectedIndex = tempTrailerImageLookup[lookUpIndex]["index"];
                        setSelectedTrailerImage({ data: response.data[selectedIndex]["image_data"], index: lookUpIndex, name: response.data[selectedIndex]["displayname"], id: response.data[selectedIndex]["trailer_number"] });

                        setTrailerImageLookup(tempTrailerImageLookup);
                        setTrailerImagesLoading(false);
                        setTrailerSelectedLoading(false);
                    }
                    else {
                        message.warning("No OCR images were found for " + tripId)
                        setTrailerImagesLoading(false);
                        setTrailerSelectedLoading(false);
                    }
                } else {
                    console.log(response);
                    message.error("Could not load OCR images for trip " + tripId + "...")
                    setTrailerImagesLoading(false);
                    setTrailerSelectedLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load OCR images for trip " + tripId + "...")
                setTrailerImagesLoading(false);
                setTrailerSelectedLoading(false);
            });
    }

    function handleDateWithTimeCheck(e) {
        setDateWithTime(e.target.checked)

    }

    function handleTrailerImageModalOpen(tripId, properties = {}) {
        setTrailerImagesModal(true);
        fetchTripTrailerImages(tripId);
        if (properties["verification"] && properties["verification"]["id"] === 3) {
            setInvalidTrip(true);
        }
        if (properties["user_verified"] != null) {
            setManualOverwrite(properties["user_verified"]);
        } else {
            setManualOverwrite("");
        }
    }

    function handleDateChange(date) {
        let dateAfter = date[0] != null ? moment(date[0].toString()) : null;
        let dateBefore = date[1] != null ? moment(date[1].toString()) : null;

        dateAfter = dateAfter ? dateAfter.format("YYYY-MM-DDTHH:mm:ss") : null;
        dateBefore = dateBefore ? dateBefore.format("YYYY-MM-DDTHH:mm:ss") : null;

        setMergeDateRange(
            {
                dateAfter,
                dateBefore
            }
        )
    }

    const onClose = () => {
        setMergingOpen(false)
    }

    async function handleTripTrailerOk() {
        setTrailerImagesModal(false);

        const modifiedRows = {};
        try {
            tripTrailerImages.forEach((currentRow, index) => {
                const initialRow = initialTripTrailer[index];
                const modifiedAttributes = {};

                Object.keys(currentRow).forEach(key => {
                    if (currentRow[key] !== initialRow[key]) {
                        modifiedAttributes[key] = currentRow[key];
                    }
                })
                if (Object.keys(modifiedAttributes).length > 0) {
                    modifiedRows[currentRow.trailer_number] = modifiedAttributes; // Save using the trailer_number as the key
                }
            })
            if (Object.keys(modifiedRows).length > 0 || manualOverwrite != null) {
                const response = await axios.post(window.vtrack_config.REACT_APP_API_URL + '/trips/' + trailerTripId + '/trailer_id_image/approve', {
                    changes: modifiedRows,
                    user_verified: manualOverwrite,
                    selected_image: selectedTrailerImage["id"],
                    invalid_trip: invalidTrip
                });

                //Update the frontend with the updated data
                const { trip_id } = response.data;
                props.updateTripRow(trip_id, response.data);
            }
        } catch (error) {
            console.error('Error sending approved trip IDs:', error);
        }
        resetTripTrailerState();
    }

    const tripFilters = {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8, width: 220 }}>
                {/* first filter */}
                <div style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        gutter={10}
                        align="middle"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>
                            <Text>Range: </Text>
                            <InputNumber
                                value={leftDuration}
                                onChange={e => setLeftDuration(e)}
                                style={{ width: 60, marginLeft: 8, marginRight: 8 }}
                            />
                            <Text> - </Text>
                            <InputNumber
                                value={rightDuration}
                                onChange={e => setRightDuration(e)}
                                style={{ width: 60, marginLeft: 8 }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Slider
                            range
                            defaultValue={[leftDuration, rightDuration]}
                            value={[leftDuration, rightDuration]}
                            onChange={e => {
                                setLeftDuration(e[0]);
                                setRightDuration(e[1]);
                            }}
                            style={{ width: '100%' }}
                        />
                    </Row>

                    <Row style={{ marginBottom: 8 }}>
                        <Button
                            block
                            size="small"
                            onClick={() => {
                                // Reset the filter values
                                setLeftDuration(null);
                                setRightDuration(null);
                            }}
                        >
                            Reset
                        </Button>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={async () => {
                                setSelectedKeys([{ ...selectedKeys[0], duration_range: {less_than: rightDuration, more_than: leftDuration} }]);
                                confirm();
                            }}
                        >
                            Confirm
                        </Button>
                    </Row>
                </div>

                <Divider style={{ margin: '8px 0' }} />

                {/* second filter */}
                <div style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        gutter={10}
                        align="middle"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>Search by trip id:</Col>
                        <Col>
                            <Input
                                placeholder="Enter trip id..."
                                value={tripIdSearch}
                                onChange={e => {
                                    setTripIdSearch(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={() => {
                                // Apply second filter logic here
                                //props.searchTrip(undefined, undefined, { trip_id: tripIdSearch });
                                setSelectedKeys([{ ...selectedKeys[0], search: {value: tripIdSearch, field: "trip_id"} }]);
                                confirm();
                            }}
                        >
                            Apply Filter
                        </Button>
                    </Row>
                </div>
            </div>
        )


    }

    const pickupIncludeExclude = {
        filters: [
            {
                text: "Include",
                value: true
            },
            {
                text: "Exclude",
                value: false
            }
        ],
        filterMultiple: false
    }
    const deliveryIncludeExclude = {
        filters: [
            {
                text: "Include",
                value: true
            },
            {
                text: "Exclude",
                value: false
            }
        ],
        filterMultiple: false
    }

    const columns = [
        {
            title: 'Trip Info', key: 'trip', dataIndex: "trip_duration", ...tripFilters, width: 180, sorter: true, render: (_val, record) =>
                <>
                    <Text strong>Trip ID: </Text>{mergedTripLink(record)}<br />
                    <Text strong>Tractor ID: </Text><Text>{record.tag}</Text><br />
                    <Text strong>Trip duration: </Text> {getTripDuration(record)}
                </>
        },
        {
            title: 'Created', key: 'created_message_time', dataIndex: 'created_message_time', width: 150, sorter: true, render: val =>
                <>
                    UTC: {!!val ? moment.utc(val).format('DD/MM/YYYY HH:mm:ss') : val}<br />
                    Local: {!!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val}

                </>

        },
        {
            title: 'Updated', key: 'updated_message_time', dataIndex: 'updated_message_time', width: 150, sorter: true, render: val =>
                <>
                    UTC: {!!val ? moment.utc(val).format('DD/MM/YYYY HH:mm:ss') : val}<br />
                    Local: {!!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val}

                </>

        },
        {
            title: 'Status', key: 'status', width: 110, sorter: true, filters: [
                { text: "UNLOAD", value: 1 },
                { text: "LOAD", value: 2 },
                { text: "YARD SHIFT", value: 3 },
                { text: "VESSEL PICKUP", value: 4 },
                { text: "YARD PICKUP", value: 5 },
                { text: "NONE", value: null }
            ],
            render: (_val, record) => {
                let trip_type_name = record.trip_type_rel ? record.trip_type_rel.name : null
                switch (record.trip_type) {
                    case 1:
                        return <Tag color="#509423">{trip_type_name ? trip_type_name : "UNLOAD"}</Tag>;
                    case 2:
                        return <Tag color="#e6833c">{trip_type_name ? trip_type_name : "LOAD"}</Tag>;
                    case 3:
                        return <Tag color="#666">{trip_type_name ? trip_type_name : "YARD SHIFT"}</Tag>;
                    case 4:
                        return <Tag color="#aaa">{trip_type_name ? trip_type_name : "VESSEL PICKUP"}</Tag>;
                    case 5:
                        return <Tag color="#aaa">{trip_type_name ? trip_type_name : "YARD PICKUP"}</Tag>;
                }
            }

        },
        {
            title: 'Pickup', key: 'pickup', dataIndex: 'pickup_time', ...pickupIncludeExclude, width: 200, sorter: true,
            render: (_val, record) =>
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text strong>Time: </Text>
                            <Text>{!!record.pickup_time ? moment.utc(record.pickup_time).format('DD/MM/YYYY HH:mm:ss') : record.pickup_time}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                                <Input value={editableFields.pickup_yard_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.pickup_yard_id, 'pickup_yard_id')}>Edit</Text>
                            </div>
                        )}

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck ID: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                                <Input value={editableFields.pickup_deck_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} className="edit-button"
                                    onClick={() => handleEditClick(record.trip_id, record.pickup_deck_id, "pickup_deck_id")}
                                >Edit</Text>
                            </div>
                        )}

                    </div>
                </React.Fragment>
        },
        {
            title: 'Discharge', key: 'discharge', dataIndex: 'discharge_time', width: 200, ...deliveryIncludeExclude, sorter: true,
            render: (_val, record) =>
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text strong>Time: </Text>
                            <Text>{!!record.discharge_time ? moment.utc(record.discharge_time).format('DD/MM/YYYY HH:mm:ss') : record.discharge_time}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                                <Input value={editableFields.discharge_yard_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.discharge_yard_id, 'discharge_yard_id')}>Edit</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck ID: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                                <Input value={editableFields.discharge_deck_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} onClick={() => handleEditClick(record.trip_id, record.discharge_deck_id, "discharge_deck_id")}>Edit</Text>
                            </div>
                        )}
                    </div>
                </React.Fragment>
        },
        {
            title: 'Trailer', key: 'trailer', dataIndex: 'trailer_number_time', filters: props.verificationTypes.map(item => ({
                text: item.name,
                value: item.id
            })), width: 200,
            sorter: true, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{(record.approved_trailers.length > 0) ? record.approved_trailers[0].trailer_number :
                        record.trailer_number
                    }</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? moment.utc(record.trailer_number_time).format('DD/MM/YYYY HH:mm:ss') : record.trailer_number_time}</Text><br />
                    {record.trailer_number != null && (
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Row gutter={8}>
                                    <Col>
                                        <Text>


                                            {/* Verification type check and inline switch case */}
                                            {record["verification_type_rel"] ? (
                                                <>
                                                    {" "}
                                                    {(() => {
                                                        switch (record["verification_type_rel"]["id"]) {
                                                            case 0: // Unverified (Red)
                                                                return <ExclamationCircleOutlined style={{ color: 'red' }} />;
                                                            case 1: // Verified (Green)
                                                            case 2: // Modified (Green)
                                                                return <CheckCircleOutlined style={{ color: 'green' }} />;
                                                            case 3: // Invalid (Yellow)
                                                                return <WarningOutlined style={{ color: 'orange' }} />;
                                                            default:
                                                                return null;
                                                        }
                                                    })()}
                                                    {" " + verificationTypes[record["verification_type_rel"]["id"]]}
                                                </>
                                            ) : (
                                                " Unverified"
                                            )}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button type="primary"
                                    size="small"
                                    icon={<CameraOutlined />}
                                    onClick={() => { handleTrailerImageModalOpen(record.trip_id, { user_verified: record.user_verified, verification: record.verification_type_rel }) }}
                                    style={{ marginLeft: 0, marginTop: 8 }}>View OCR</Button>
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
        },
    ];

    const tripTrailerColumns = [
        {
            title: 'Trailer ID',
            dataIndex: 'trailer_number',
            key: 'trailer_number',
            render: text => <Text strong>{text}</Text>,
        },
        {
            title: 'Confidence',
            dataIndex: 'confidence',
            key: 'confidence',
            width: 32,
            sorter: (a, b) => a.confidence - b.confidence,
            sortDirections: ['descend', 'ascend'],
            render: text => <Text>{text}</Text>,
        },
        {
            title: 'Image',
            dataIndex: 'displayname',
            key: 'displayname',
            render: (imageData, record) =>
                <Text>{(imageData == null || imageData == "") ? record.image_id : imageData}</Text>
        },
        {
            title: 'Count',
            dataIndex: 'updated_count',
            key: 'updated_count',
            width: 32,
            sorter: (a, b) => a.updated_count - b.updated_count,
            defaultSortOrder: "descend",
            render: text => <Text>{text + 1}</Text>,
        },
        {
            title: 'Approved',
            dataIndex: 'user_approved',
            width: 32,
            key: 'user_approved',
            render: (checked, record) => <Checkbox defaultChecked={!invalidTrip && checked} checked={!invalidTrip && checked}
                onChange={(e) => handleTripTrailerChange(record.trailer_number, "user_approved", e.target.checked)}
                disabled={invalidTrip ? true : checked ? false : (manualOverwrite !== "" && manualOverwrite !== null)} />,
        }
    ];

    function handleTripTrailerChange(trailerNumber, key, value) {
        for (let i = 0; i < tripTrailerImages.length; i++) {
            if (tripTrailerImages[i]["trailer_number"] == trailerNumber) {
                if (key == "user_approved") {
                    setTripTrailerImages(prevState => {
                        const updatedState = prevState.map(item => ({
                            ...item,
                            user_approved: item.trailer_number === trailerNumber ? value : false
                        }));

                        // Now use the updatedState to check for approvals
                        const approved = updatedState.some(item => item.user_approved);
                        setUserApprovedChecked(approved);
                        return updatedState;
                    });
                }
                if (key == "manual_overwrite") {
                    setTripTrailerImages(prevState =>
                        prevState.map(item =>
                            item.trailer_number === trailerNumber ? { ...item, [key]: value } : item
                        )
                    );
                }
            }
        }
    }

    function handleTableChange(pagination, filters, sorter) {
        setCurrentPage(pagination.current);
        console.log(filters);
        if (!merging) {
            // Cant set selected keys in the filterdropdown to an object, only an array
            props.fetchData(pagination.current, undefined, {
                sortField: sorter.field,
                sortOrder: sorter.order,
                filters: filters
            })
        } else {
            let { dateAfter, dateBefore } = mergeDateRange
            dateAfter = dateAfter != null ? moment(dateAfter) : null;
            dateBefore = dateBefore != null ? moment(dateBefore) : null;
            if (!dateWithTime && dateAfter && dateBefore) {
                // Set dateAfter to the start of the day
                dateAfter = dateAfter.startOf('day').format("YYYY-MM-DDTHH:mm:ss");
                // Set dateBefore to the end of the same day
                dateBefore = dateBefore.endOf('day').format("YYYY-MM-DDTHH:mm:ss");
            } else {
                // If the dates are not on the same day (or with time is checked), format them as usual
                dateAfter = dateAfter ? dateAfter.format("YYYY-MM-DDTHH:mm:ss") : null;
                dateBefore = dateBefore ? dateBefore.format("YYYY-MM-DDTHH:mm:ss") : null;
            }
            let params = { date: { dateAfter: dateAfter, dateBefore: dateBefore }, tag: "863500060464901", durationThreshold: mergeThreshold }
            props.fetchMergedTrips(pagination.current, undefined, params)
        }
    }

    function mergingOnSubmit() {
        setMerging(true);
        let { dateAfter, dateBefore } = mergeDateRange
        dateAfter = dateAfter != null ? moment(dateAfter) : null;
        dateBefore = dateBefore != null ? moment(dateBefore) : null;
        if (!dateWithTime && dateAfter && dateBefore) {
            // Set dateAfter to the start of the day
            dateAfter = dateAfter.startOf('day').format("YYYY-MM-DDTHH:mm:ss");
            // Set dateBefore to the end of the same day
            dateBefore = dateBefore.endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        } else {
            // If the dates are not on the same day (or with time is checked), format them as usual
            dateAfter = dateAfter ? dateAfter.format("YYYY-MM-DDTHH:mm:ss") : null;
            dateBefore = dateBefore ? dateBefore.format("YYYY-MM-DDTHH:mm:ss") : null;
        }
        let params = { date: { dateAfter: dateAfter, dateBefore: dateBefore }, tag: "863500060464901", durationThreshold: mergeThreshold }
        props.fetchMergedTrips(undefined, undefined, params)
    }


    function handleTrailerImageSelect(trailerNumber) {
        const foundIndex = tripTrailerImages.findIndex(item => item.trailer_number == trailerNumber);

        if (foundIndex !== -1) {
            // Set the selectedTrailerImage using the found object
            setSelectedTrailerImage({
                data: tripTrailerImages[foundIndex]["image_data"],
                index: trailerImageLookup.findIndex(obj => obj.index === foundIndex),
                name: tripTrailerImages[foundIndex]["displayname"],
                id: tripTrailerImages[foundIndex]["trailer_number"]
            });
        } else {
            console.error(`Trailer with number ${trailerNumber} not found`);
        }
    }


    return (
        <>
            <style>{`
                .edit-button {
                    visibility: hidden;
                }
                .edit-button > span {
                    color: blue;
                }
                .editable-row:hover .edit-button {
                    visibility: visible;
                }
            `}</style>
            <Button
                type="primary"
                size="large"
                icon={<MergeCellsOutlined />}
                onClick={() => setMergingOpen(true)}
                style={{ marginLeft: 32, marginTop: 8 }}
            >
                Merge
            </Button>
            <Modal
                title="Trip merging"
                open={mergingOpen}
                onCancel={onClose}
                footer={[
                    <Button key="submit" type="primary" onClick={() => { mergingOnSubmit(); }}>
                        Merge trips
                    </Button>
                ]}
                style={{}}
            >
                <Row gutter={16} type="flex" style={{ marginBottom: 8 }}>
                    <Col>Minutes: </Col>
                    <Col>
                        <InputNumber
                            value={mergeMinutes}
                            onChange={e => {

                                setMergeMinutes(e)
                            }} />
                    </Col>
                    <Col>Seconds: </Col>
                    <Col>
                        <InputNumber
                            value={mergeSeconds}
                            onChange={e => {
                                setMergeSeconds(e)
                            }} />
                    </Col>
                </Row>
                <Row>
                    <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Date range</Text>
                    <Checkbox checked={dateWithTime} onChange={handleDateWithTimeCheck}>
                        Date with time
                    </Checkbox>

                    <RangePicker showTime={dateWithTime}
                        allowEmpty={[true, true]}
                        style={{ width: "100%" }}
                        onChange={(value) => { handleDateChange(value) }} />
                </Row>
            </Modal>
            <TripTrailerMoal
                trailerImageLookup={trailerImageLookup}
                selectedTrailerImage={selectedTrailerImage}
                tripTrailerImages={tripTrailerImages}
                trailerTripId={trailerTripId}
                trailerImagesModal={trailerImagesModal}
                handleModalCancel={resetTripTrailerState}
                handleTripTrailerOk={handleTripTrailerOk}
                trailerSelectedLoading={trailerSelectedLoading}
                setManualOverwrite={setManualOverwrite}
                manualOverwrite={manualOverwrite}
                userApprovedChecked={userApprovedChecked}
                handleTrailerImageSelect={handleTrailerImageSelect}
                tripTrailerColumns={tripTrailerColumns}
                trailerImagesLoading={trailerImagesLoading}
                setSelectedTrailerImage={setSelectedTrailerImage}
                invalidTrip={invalidTrip}
                setInvalidTrip={setInvalidTrip}
            />

            <ProTable
                columns={columns}
                loading={props.loading}
                pagination={{
                    pageSize: props.page_size, current: currentPage, showSizeChanger: false, size: "small", hideOnSinglePage: false, style: { marginBottom: 0 }, total: props.count
                }}
                bordered={true}
                expandable={{
                    expandedRowRender: record => { return <TripEventSubTable tripId={record.trip_id} /> },
                    rowExpandable: () => 1 > 0,
                }}
                rowKey="trip_id"
                dataSource={props.data}
                scroll={{ y: props.scroll }}
                search={false}
                options={{ 'reload': () => props.fetchData() }}
                onChange={handleTableChange}
            />
        </>

    )

}
