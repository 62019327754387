import React, { useState, useEffect } from "react";
import moment from 'moment';
import $ from 'jquery'
import 'leaflet/dist/leaflet.css';
import axios from 'axios';

import { singleTripLink, tripTypeColor } from "../Helpers";
import { MapContainer, TileLayer, Popup, Circle, ImageOverlay, Polyline, Polygon, useMapEvents, GeoJSON, LayersControl } from 'react-leaflet';
const { BaseLayer } = LayersControl;

import { ReloadOutlined, FilterOutlined, GlobalOutlined } from "@ant-design/icons";
import { Button, Typography, Select, Row, Col, Radio, Checkbox } from 'antd';
const { Text } = Typography;

// Map controls
const position = [51.9054, 4.3612]
const mapBounds = [[51.901172, 4.358327], [51.90955, 4.368601]]
// Should preload the image
new Image().src = './images/port_map.jpg';
const dockMap = <ImageOverlay id="map" bounds={mapBounds} url='./images/port_map.jpg' opacity={0.5} />


export default function Map(props) {

    const [trailerPositions, setTrailerPositions] = useState([]);
    const [tractorPositions, setTractorPositions] = useState([]);
    const [tripPositions, setTripPositions] = useState([]);
    const [parkingSlots, setParkingSlots] = useState([])
    const [parkingSlotsConfigurations, setParkingSlotsConfigurations] = useState([])

    // Filters
    const [filtersVisible, toggleFilters] = useState(true)
    const [tractorFilter, setTractorFilter] = useState([]);
    const [trailerFilter, setTrailerFilter] = useState([]);
    const [tripFilter, setTripFilter] = useState([]);
    const [tripTractorFilter, setTripTractorFilter] = useState([]);
    const [parkingSlotFilter, setParkingSlotFilter] = useState([]);
    const [tripTypeFilter, setTripTypeFilter] = useState([]);

    // Lookups for filters
    const [tractorLookup, setTractorLookup] = useState([]);
    const [trailerLookup, setTrailerLookup] = useState([]);
    const [tripLookup, setTripLookup] = useState([]);
    const [parkingSlotLookup, setParkingSlotLookup] = useState([])

    // Map switch
    const [showMap, toggleMap] = useState(true);

    // Mode
    const [mode, setMode] = useState("equipment")
    //Show parking sector configuration points 
    const [showSectorConfig, setShowSectorConfig] = useState(false)

    //User circle on click
    const [cicrcleOnClick, setCicrcleOnClick] = useState([])

    const legendItems = [
        { color: "orange", label: "Unknown", "type": "parking_slot" },
        { color: "red", label: "Occupied", "type": "parking_slot" },
        { color: "green", label: "Free", "type": "parking_slot" },
        { color: "blue", label: "Allocated", "type": "parking_slot" },
        { color: "black", label: "Unofficial", "type": "parking_slot_unofficial" },
        { color: "#02df02", label: "Trip load", "type": "trip" },
        { color: "#8c8cf7", label: "Trip unload", "type": "trip" },
        { color: "teal", label: "Trip yard shift", "type": "trip" }
    ];

    const tripTypesLookup = [
        { label: <span>UNLOAD</span>, value: 1 },
        { label: <span>LOAD</span>, value: 2 },
        { label: <span>YARD SHIFT</span>, value: 3 },
        // { label: <span>VESSEL PICKUP</span>, value: 4 },
        // { label: <span>YARD PICKUP</span>, value: 5 },
        // { label: <span>NONE</span>, value: null }
    ]

    function fetchMapData(){
        props.fetchTrailers(); props.fetchTractors(); props.fetchTrips(); props.fetchParkingSlots() 
    }
    // useEffect(() => {
    //     // Call fetchMapData initially
    //     fetchMapData();
    
    //     // Set up the interval to call fetchMapData every x seconds
    //     const intervalId = setInterval(fetchMapData, 10 * 1000);
    
    //     // Cleanup the interval on component unmount
    //     return () => clearInterval(intervalId);
    //   }, []);

    useEffect(() => {
        let allTrailers = []
        let newTrailers = []
        props.trailers.map(row => {
            if (row.position_latitude && row.position_longitude) {
                // Apply filters
                if (tripFilter.length == 0 && (trailerFilter.length == 0 || trailerFilter.includes(row.trailer_number))) {
                    newTrailers.push({
                        "id": row.trailer_number,
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time,
                        "yard_id": row.yard_id
                    })
                }
                allTrailers.push({ value: row.trailer_number })
            }
        })
        setTrailerPositions(newTrailers)
        setTrailerLookup(allTrailers)
    }, [props.trailers])

    useEffect(() => {
        let allParkingSectors = []
        let newParkingSectors = []
        let temp = []
        props.parkingSlots.map(row => {
            // Apply filters
            if (parkingSlotFilter.length == 0 || parkingSlotFilter.includes(row.id.charAt(0))) {
                newParkingSectors.push(row)
            }
            if (!temp.includes(row.id.charAt(0))) {
                allParkingSectors.push({ value: row.id.charAt(0) })
                temp.push(row.id.charAt(0))
            }
        })

        setParkingSlots(newParkingSectors)
        setParkingSlotLookup(allParkingSectors)
    }, [props.parkingSlots])


    useEffect(() => {
        let allTractors = []
        let newTractors = []
        // Data could change due to new data zoom tractors. Intersect them with currect tractor filter.
        // Find out new tractor filter value
        let newTractorFilter = props.dataZoomTractors.length == 0 ? tractorFilter : tractorFilter.filter(tractor => props.dataZoomTractors.includes(tractor))
        props.tractors.map(row => {
            if (row.position_latitude && row.position_longitude) {
                // Apply filters (newTractorFilter === tractorFilter will redner here instead of in filter update)
                if (newTractorFilter === tractorFilter && tripFilter.length == 0 && (newTractorFilter.length == 0 || newTractorFilter.includes(row.tag))) {
                    newTractors.push({
                        "id": row.tag,
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time
                    })
                }
                allTractors.push({ value: row.tag })
            }
        })
        setTractorFilter(newTractorFilter) // if current filter != new filter rerender will happen here
        setTractorPositions(newTractors)
        setTractorLookup(allTractors)
    }, [props.tractors])

    useEffect(() => {
        let allTrips = []
        let newTrips = []

        props.trips.trips.map(row => {
            if (row.pickup_latitude && row.pickup_longitude && Array.isArray(row.path)) {
                row.path = row.path
                .filter(({ loc_lat, loc_lon }) => loc_lat !== null && loc_lon !== null)
                .map(({ loc_lat, loc_lon }) => [loc_lat, loc_lon]);
                // // Apply filters
                if ((tripFilter.length === 0 || tripFilter.includes(row.trip_id)) && (tripTractorFilter.length === 0 || tripTractorFilter.includes(row.tag))) newTrips.push(row)
                allTrips.push({ value: row.trip_id })
            }
        })

        setTripPositions(newTrips)
        setTripLookup(allTrips)
    }, [props.trips.trips])

    // Watch and handle filter changes
    useEffect(() => {
        handleFilter("tractor")
    }, [tractorFilter])

    useEffect(() => {
        handleFilter("trailer")
    }, [trailerFilter])

    useEffect(() => {
        handleFilter("parking_slot")
    }, [parkingSlotFilter])

    useEffect(() => {
        handleFilter("trip")
    }, [tripFilter])

    useEffect(() => {
        handleFilter("trip")
    }, [tripTypeFilter])

    useEffect(() => {
        handleFilter("trip_tractor")
    }, [tripTractorFilter])

    function handleFilter(category) {
        if (category === "tractor") {
            let newTractors = []
            props.tractors.map(row => {
                // check if coordinates exist
                if (!row.position_latitude || !row.position_longitude) return
                // Apply filters
                if (tractorFilter.length == 0 || tractorFilter.includes(row.tag)) {
                    newTractors.push({
                        "id": row.tag,
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time
                    })
                }
            })
            setTractorPositions(newTractors)
        }
        if (category == "trailer") {
            let newTrailers = []
            props.trailers.map(row => {
                if (!row.position_latitude || !row.position_longitude) return
                // Apply filters
                if (trailerFilter.length == 0 || trailerFilter.includes(row.trailer_number)) {
                    newTrailers.push({
                        "id": row.trailer_number,
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time,
                        "yard_id": row.yard_id
                    })
                }
            })
            setTrailerPositions(newTrailers)
        }
        if (category === "trip") {
            let newTrips = []
            props.trips.trips.map(row => {
                if (!row.pickup_latitude || !row.pickup_longitude) return
                if ((tripFilter.length === 0 || tripFilter.includes(row.trip_id))
                    && (tripTractorFilter.length === 0 || tripTractorFilter.includes(row.tag))
                    && (tripTypeFilter.length === 0 || tripTypeFilter.includes(row.trip_type))) newTrips.push(row)
            })
            setTripPositions(newTrips)
        }
        if (category === "trip_tractor") {
            // handle Trips associated with selected tractors
            let tempTripsLookup = []
            props.trips.trips.map(row => {
                if (tripTractorFilter.length === 0 || tripTractorFilter.includes(row.tag)) {
                    tempTripsLookup.push(row.trip_id)
                }
            })
            setTripLookup(tempTripsLookup.map(e => { return { value: e } }))
            setTripFilter(filter => {
                let newFilter = filter.filter(id => tempTripsLookup.includes(id))
                return newFilter
            })
        }
        if (category == "parking_slot") {
            let newParkingSectors = []
            props.parkingSlots.map(row => {
                if (parkingSlotFilter.length == 0 || parkingSlotFilter.includes(row.id.charAt(0))) {
                    newParkingSectors.push(row)
                }
            })
            setParkingSlots(newParkingSectors)
        }
    }

    const fetchButton =
        <Button
            style={{ width: 145 }}
            onClick={() => { props.fetchTrailers(); props.fetchTractors(); props.fetchTrips(); props.fetchParkingSlots() }}
            loading={props.loading}
            icon={<ReloadOutlined />}>
            Fetch data
        </Button>

    const selectTractorsBox =
        <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={tractorFilter}
            options={tractorLookup}
            onChange={val => setTractorFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all tractors..."
        />

    const selectTrailersBox =
        <Select
            mode="multiple"
            style={{ width: "100%" }}
            allowClear
            value={trailerFilter}
            options={trailerLookup}
            onChange={val => setTrailerFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all trailers..."
        />

    const selectTripTractorsBox =
        <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={tripTractorFilter}
            options={tractorLookup}
            onChange={val => setTripTractorFilter(val)}
            maxTagCount="responsive"
            placeholder="Selecting all tractors..."
        />

    const selectTripsBox =
        <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={tripFilter}
            options={tripLookup}
            onChange={val => setTripFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all trips for selected tractors..."
        />

    const selectParkingSlotsBox =
        <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={parkingSlotFilter}
            options={parkingSlotLookup}
            onChange={val => setParkingSlotFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all parking slots..."
        />

    const selectTripTypeBox =
        <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            options={tripTypesLookup}
            value={tripTypeFilter}
            onChange={val => setTripTypeFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all trips" />

    const toggleFiltersButton =
        <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => { showhide('filters') }}
            style={{ width: 145 }}
        >
            {filtersVisible ? "Hide filters" : "Show filters"}
        </Button>

    const legendDropdown =
        <div style={{
            padding: 10,
            border: '1px solid rgba(255, 255, 255, 0.4)',
            borderRadius: 8,
            background: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
        }}>
            <div style={{ marginBottom: 8, fontWeight: 'bold', color: '#333' }}>Legend</div>
            {legendItems.map((item, index) => {
                if (item.type == "parking_slot_unofficial") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 22,
                                marginRight: 8,
                                border: '3px dotted #000'
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }
                if (item.type == "parking_slot") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 22,
                                background: item.color,
                                marginRight: 8,
                                border: '1px solid #000'
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }
                if (item.type == "trip") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 6,
                                background: item.color,
                                marginRight: 8
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }

            })}
        </div>

    const filtersBox =
        <div id="filtersbox" style={{ zIndex: 998, position: "absolute", top: 4, right: 4, padding: 4 }}>
            <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                <div style={{ width: 142 }}>
                    {legendDropdown}
                </div>
                <div style={{ width: 453 }}>
                    <Row gutter={4} type="flex" justify="end">
                        <Col>
                            {toggleFiltersButton}
                        </Col>
                        <Col>
                            {fetchButton}
                        </Col>
                    </Row>
                    <Row type="flex">
                        <Col span={24}>
                            <div id="filters">
                                <Row>
                                    <Col span={24} style={{ marginTop: 4 }}>
                                        <Radio.Group onChange={e => setMode(e.target.value)} buttonStyle="solid" defaultValue="equipment" style={{ width: '100%' }}>
                                            <Radio value="equipment"><Text strong>Current equipment location</Text></Radio>
                                            <Radio value="trip"><Text strong>Trip history</Text></Radio>
                                        </Radio.Group>
                                    </Col>

                                    <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Parking slots</Text>
                                    {selectParkingSlotsBox}

                                    {parkingSlotFilter.length > 0 &&
                                        <Checkbox onChange={e => setShowSectorConfig(e.target.checked)}><Text>Sector configuration</Text></Checkbox>}

                                    {mode === "equipment" && <Col span={24}>
                                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Tractors</Text>
                                        {selectTractorsBox}
                                    </Col>}
                                    {mode === "equipment" && <Col span={24}>
                                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Trailers</Text>
                                        {selectTrailersBox}
                                    </Col>}
                                    {mode === "trip" && <Col span={24}>
                                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Tractors</Text>
                                        {selectTripTractorsBox}
                                    </Col>}
                                    {mode === "trip" && <Col span={24}>
                                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Trips</Text>
                                        {selectTripsBox}
                                    </Col>}
                                    {mode === "trip" && <Col span={24}>
                                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Trip types</Text>
                                        {selectTripTypeBox}
                                    </Col>}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


        </div>

    // JQuery functions
    function showhide(id) {
        if (document.getElementById) {
            var divid = document.getElementById(id);
            // var divs = document.getElementsByClassName("hideable");
            if (filtersVisible) {
                $(divid).fadeOut("slow");
            } else {
                $(divid).fadeIn("slow");
            }
            toggleFilters(!filtersVisible)
        }
        return false;
    }

    // function modifyFilterBackground(show){
    //     var divid = document.getElementById("map_container")
    //     console.log(divid)
    //     if (show){
    //         $(divid).attr({"opacity": 0.5});
    //     } else {
    //         $(divid).attr({"opacity": 0.0});
    //     }
    //     return false;
    // }


    // const GetCoordinates = () => {
    //     useMapEvents({
    //         async click(e) {
    //             // setState your coords here
    //             // coords exist in "e.latlng.lat" and "e.latlng.lng"
    //             console.log(Math.round(e.latlng.lat * 1000000) / 1000000, Math.round(e.latlng.lng * 1000000) / 1000000);
    //             setCicrcleOnClick([e.latlng.lat, e.latlng.lng])
    //             let qparams = {}
    //             qparams["latitude"] = e.latlng.lat
    //             qparams["longitude"] = e.latlng.lng
    //             await axios.get(window.vtrack_config.REACT_APP_API_URL + '/parking-slot', { params: qparams })
    //                 .then(response => {
    //                     if (response.status === 200) {
    //                         console.log(response.data)
    //                     } else {
    //                         console.log(response);
    //                         message.error("Error checking if point is inside polygon")
    //                     }
    //                 })
    //         },
    //     });
    //     return false;
    // }


    // const coordinates = Object.values(ALLEY_CHOICES).map(choice => [
    //     [choice.alley_len.x1, choice.alley_len.y1],
    //     [choice.alley_len.x2, choice.alley_len.y2]
    // ]);
    // const turns = Object.values(ALLEY_CHOICES).map(choice => [choice.turn.x, choice.turn.y])

    return (
        <MapContainer
            id="map_container"
            center={position}
            zoom={18}
            zoomSnap={0.25}
            scrollWheelZoom={true}
            style={{ height: '100%', width: '100%', minWidth: 500 }}
        >
            {filtersBox}

            <LayersControl position="topleft">
                <BaseLayer checked name="Mapbox Satellite">
                    <TileLayer
                        attribution='&copy; <a https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                        url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia2FqYXZhLWp5cmkiLCJhIjoiY2x2cDFzNGRvMDJjbjJtcm8wdjF6YjNtciJ9.X4WsWxyul5MaNavSQE-PjA`}
                        maxZoom={22}
                    />
                </BaseLayer>
                <BaseLayer name="OpenStreetMap">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom={22}
                    />
                </BaseLayer>
            </LayersControl>

            {parkingSlots.map(poly =>
                <React.Fragment key={poly.id}>
                    {poly.line &&
                        <Polyline pathOptions={{ color: "lime" }} positions={poly.line}></Polyline>
                    }
                    {poly.polygon &&
                        <Polygon pathOptions={{ color: poly.color || "orange", weight: 2, dashArray: poly.unofficial ? 8 : 0 }} positions={poly.polygon}>
                            <Popup closeButton={false}>
                                <Text strong>Yard ID: </Text>{poly.id}<br />
                                <Text strong>Status: </Text>{poly.status}<br />
                                <Text strong>Trailer: </Text>{poly.trailer}<br />
                                <Text strong>Last Updated: </Text>{poly.updated}<br />
                                {poly.trip_id && <Text strong>Trip ID: </Text>} {singleTripLink(poly.trip_id)}<br />
                            </Popup>
                        </Polygon>
                    }

                </React.Fragment>

            )}
            {showSectorConfig && parkingSlotFilter.map(sector =>
                <React.Fragment key={`${sector}_config`}>
                    {props.parkingSlotsConfigurations[sector].map((pnt, index) => {
                        if (pnt.geometry.type === "Point") {
                            return (
                                <Circle key={`${sector}-${pnt.properties.config_point}-${index}`} center={pnt.geometry.coordinates} radius={2}>
                                    <Popup>
                                        <Text strong>Configuration point: </Text>{pnt.properties.type}<br />
                                        <Text strong>Yard ID: </Text>{pnt.properties.id}
                                    </Popup>
                                </Circle>
                            );
                        } else if (pnt.geometry.type === "MultiPoint") {
                            return pnt.geometry.coordinates.map((coord, idx) => (
                                <Circle key={`${sector}-${pnt.properties.config_point}-${index}-${idx}`} center={coord} radius={2}>
                                    <Popup>
                                        <Text strong>Configuration point: </Text>{pnt.properties.type}<br />
                                        <Text strong>Yard ID: </Text>{pnt.properties.id}
                                    </Popup>
                                </Circle>
                            ));
                        } else {
                            return null; // Handle other geometry types if needed
                        }
                    })}
                </React.Fragment>
            )}



            {mode === "equipment" && tractorPositions.map(trailer =>
                <Circle key={trailer.id} center={[trailer.latitude, trailer.longitude]} radius={3} color="#222">
                    <Popup closeButton={false}>
                        <Text strong>Tractor: </Text>{trailer.id}<br />
                        <Text strong>Time: </Text>{!!trailer.time && moment.utc(trailer.time).format('DD/MM/YYYY HH:mm:ss')} <br />
                        <Text strong>Lat: </Text>{trailer.latitude} <Text strong>Long: </Text>{trailer.longitude}
                    </Popup>
                </Circle >
            )}

            {mode === "equipment" && trailerPositions.map(trailer =>
                <Circle key={trailer.id} center={[trailer.latitude, trailer.longitude]} radius={2}>
                    <Popup closeButton={false}>
                        <Text strong>Trailer #: </Text>{trailer.id}<br />
                        <Text strong>Time: </Text>{!!trailer.time && moment.utc(trailer.time).format('DD/MM/YYYY HH:mm:ss')} <br />
                        <Text strong>Lat: </Text>{trailer.latitude} <Text strong>Long: </Text>{trailer.longitude} <br />
                        <Text strong>Yard ID: </Text> {trailer.yard_id}
                    </Popup>
                </Circle >
            )}
            {mode === "trip" && tripPositions.length > 0 && tripPositions.map(trailer =>
                <React.Fragment key={trailer.trip_id}>
                    {!!trailer.discharge_latitude && !!trailer.discharge_longitude && <Polyline pathOptions={{
                        color: tripTypeColor[trailer.trip_type]
                    }} positions={trailer.path.filter(coord => coord && coord[0] !== undefined && coord[1] !== undefined)} >
                        <Popup>
                            <Text>Trip ID: </Text>{singleTripLink(trailer.trip_id)}<br />
                            <Text>Tractor: </Text> {trailer.tag}<br />
                            <Text>Started: </Text> {moment.utc(trailer.created).format('DD/MM/YYYY HH:mm:ss')}
                        </Popup>
                    </Polyline>}
                    <Circle key={trailer.trip_id + trailer.tag} center={[trailer.pickup_latitude, trailer.pickup_longitude]} radius={3} color="#e6833c">
                        <Popup closeButton={false}>
                            <Text strong>Action: </Text>PICKUP<br />
                            <Text strong>Trip: </Text>{trailer.trip_id}<br />
                            <Text strong>Tractor: </Text>{trailer.tag}<br />
                            <Text strong>Trailer #: </Text>{trailer.trailer_number || "---"}<br />
                            <Text strong>Pickup Time: </Text>{!!trailer.pickup_time && moment.utc(trailer.pickup_time).format('DD/MM/YYYY HH:mm:ss')} <br />
                            <Text strong>Lat: </Text>{trailer.pickup_latitude} <Text strong>Long: </Text>{trailer.pickup_longitude}
                        </Popup>
                    </Circle>
                    {!!trailer.discharge_latitude && !!trailer.discharge_longitude &&
                        <Circle key={trailer.trip_id + trailer.trailer_number} center={[trailer.discharge_latitude, trailer.discharge_longitude]} radius={3} color="#509423">
                            <Popup closeButton={false}>
                                <Text strong>Action: </Text>DISCHARGE<br />
                                <Text strong>Trip: </Text>{trailer.trip_id}<br />
                                <Text strong>Tractor: </Text>{trailer.tag}<br />
                                <Text strong>Trailer #: </Text>{trailer.trailer_number || "---"}<br />
                                <Text strong>Discharge Time: </Text>{!!trailer.discharge_time && moment.utc(trailer.discharge_time).format('DD/MM/YYYY HH:mm:ss')} <br />
                                <Text strong>Lat: </Text>{trailer.discharge_latitude} <Text strong>Long: </Text>{trailer.discharge_longitude}
                            </Popup>
                        </Circle>
                    }
                </React.Fragment>
            )}
            {cicrcleOnClick.length > 0 &&
                <Circle center={cicrcleOnClick} radius={1} color="blue">

                </Circle>
            }

            {/* {props.parkingSlots.length > 0 && props.parkingSlots.map(poly => 
                <React.Fragment key={poly["id"]}>
                    <Polygon pathOptions={{color: "red"}} positions={poly["polygon"]}></Polygon>
                </React.Fragment>
                
                )} */}

            {/* <Polygon pathOptions={{color: "green", opacity: 0.1}} positions={port_area_poly}></Polygon> */}


            {/* <GetCoordinates /> */}
        </MapContainer>
    )
}