import ProTable from '@ant-design/pro-table';
import { Typography, message } from 'antd';
import { useEffect, useState, PureComponent } from 'react';
import axios from 'axios';
import React from "react";
const { Text } = Typography;
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { tripTypeColor } from '../Helpers';

export default function Terminal(props) {
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);

    const options = {

        data: data,
        series: [
            { type: 'bar', xKey: 'trip_date', yKey: 'unloadings', yName: 'Unloadings', stacked: true },
            { type: 'bar', xKey: 'trip_date', yKey: 'loadings', yName: 'Loadings', stacked: true },
            { type: 'bar', xKey: 'trip_date', yKey: 'yard_shifts', yName: 'Yard shifts', stacked: true },
            { type: 'bar', xKey: 'trip_date', yKey: 'empty_trips', yName: 'Empty trips', stacked: true }
        ],
    }




    useEffect(async () => {
        props.fetchData();

        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/metrics/trips_per_day")
            .then(response => {
                if (response.status == 200) {
                    console.log(response.data.map(d => ({
                        date: new Date(d.trip_date).toDateString(), // Full date for tooltip
                        weekday: d.weekday_name                     // Weekday name for labels
                    }))
                    )
                    setData(response.data);
                } else {
                    console.log(response);
                    message.error("Could not load terminal statistics")
                }
            })
            .catch(error => {
                console.log(error);
                message.error("could not load terminal statistics");
            })
    }, [])


    // const axes = [
    //     {
    //         primary: true,
    //         type: 'time',
    //         position: 'bottom',
    //         show: true,  // Ensure x-axis is shown
    //         tickCount: data[0]?.data.length || 30,  // Adjust number of ticks to match number of data points (bars)
    //         format: (date) => `${date.toLocaleDateString('en-UK', { month: 'short', day: 'numeric' })} (${date.toLocaleDateString('en-UK', { weekday: 'short' })})`
    //     },
    //     { type: 'linear', position: 'left' }
    // ];

    //console.log(data[0]?.data.length || 30);

    const columns = [
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: 150,
            render: (text, record) =>
                <>
                    <Text>{text.charAt(0).toUpperCase() + text.slice(1)}</Text>
                </>

        },
        {
            title: 'Trips',
            dataIndex: 'trips',
            key: 'trips',
        },
        {
            title: "24 hours",
            dataIndex: "last_24_hours",
            key: "last_24_hours",
        },
        {
            title: "Today",
            dataIndex: "today",
            key: "today"
        },
        {
            title: "Yesterday",
            dataIndex: "yesterday",
            key: "yesterday"
        },
        {
            title: "Last 7 days",
            dataIndex: "last_7_days",
            key: "last_7_days"
        }
    ]


    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const date = new Date(payload.value);
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`${date.toLocaleDateString("en-UK", {
                            month: "short",
                            day: "numeric",
                        })} (${date.toLocaleDateString("en-UK", { weekday: "short" })})`}
                    </text>
                </g>
            );
        }
    }

    return (
        <>
            <ProTable
                columns={columns}
                dataSource={props.data}
                loading={props.loading}
                search={false}
            />

            <h2>Trips per Day</h2>
            <div style={{ height: '600px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={700}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 128,
                        }}
                        padding={{
                            bottom: 160,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="trip_date"
                            height={60}
                            tick={<CustomizedAxisTick />}
                            interval={0}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend wrapperStyle={{ marginBottom: "-48px" }} />
                        <Bar dataKey="unloadings" stackId="a" fill={tripTypeColor[1]} />
                        <Bar dataKey="loadings" stackId="a" fill={tripTypeColor[2]} />
                        <Bar dataKey="yard_shifts" stackId="a" fill={tripTypeColor[3]} />
                        <Bar dataKey="empty_trips" stackId="a" fill={tripTypeColor[0]} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}